import React, {useState} from 'react';

import Form from './Form';
import useInitTerm from "../../hooks/useInitTerm";
import TermModal from '../common/Modals/TermsModal';

export default () => {
    const [cookiesBannerActive, setCookiesBannerActive] = useState(true);
    const initTerm = useInitTerm();

    return (
        <div id="middle" className="middle-box">

            <div className="middle-box__wrapp-middle">
                <div className="banner-box">
                <Form/>
                </div>
            </div>

            <div className="carousel-bg-wrapp">
            </div>

            <div className={`banner-cookie ${cookiesBannerActive ? 'open-popup_opened' : ''} open-popup-cookies`}>
                <span className="banner-cookie__close" aria-label="close" onClick={() => setCookiesBannerActive(false)}/>
                <p>By continuing to use our site you are agreeing to our cookie policy</p>
                <mya onClick={() => initTerm.openTermModal('cookie-policy')}>Learn more</mya>
            </div>

            <div className="copy copy_wrapp open-popup_opened">
                <div className="container-full text_center">
                    <div className="copy__txt">© 2021 love-in-one-click.com All rights reserved</div>
                    <ul className="copy__list-links">
                        <li><mya onClick={() => initTerm.openTermModal('terms-and-conditions')}>Terms and Conditions</mya></li>
                        <li><mya  onClick={() => initTerm.openTermModal('privacy-policy')}>Privacy Policy</mya></li>
                    </ul>
                </div>
            </div>

            <TermModal {...initTerm}/>
        </div>
    )
}