import React from 'react';

import * as IMAGES from "../../img";

export default () => {
    return(
        <header id="header" className="header">
            <div className="container-full">

                <div className="header__inner">
                    <div className="logo"><mya><img src={IMAGES.default.logo} alt="logotype"/></mya></div>
                </div>

            </div>
        </header>
    )
}