import React from 'react';

export default () => {
    return (
        <>
            <p>This Cookie Policy describes what cookies and similar technologies are, what types of cookies are placed on your device when you visit the Website and how we use them. This Cookie Policy does not address how we deal with your personal data generally. To learn more about how we process your personal data, please see our Privacy Policy. We encourage you to read this policy so that you can understand what information is collected when you visit or interact with the Website, and how we or our service providers may use cookies, web beacons, and other similar technologies for storing information about your interactions with the Website to help provide you with a better, faster, and safer experience and for advertising purposes. Please check the Terms and Conditions for the meaning of defined words (those with capital letters) not explicitly defined in this Cookie Policy.&nbsp;</p>
            <p>You are not permitted to collect and use personal data of other members in any way.</p>
            <p>&nbsp;</p>
            <p><strong>1. What are cookies?</strong></p>
            <p>Cookies are text files, containing small amounts of technically relevant information, which are downloaded mostly to/by a web browser on your device (such as a computer or smartphone, etc.) when you visit a website. Cookies can be recognized by the website that downloaded them or other websites that use the same cookies.&nbsp;</p>
            <p>&nbsp;</p>
            <p><strong>2. What are web beacons?</strong></p>
            <p>Some of our web pages may contain electronic images known as web beacons (sometimes also called clear gifs or pixel tags). Web beacons are usually small images located on a webpage or email that you are viewing. The request that your Internet-connected device makes to fetch the image from the server/computer is recorded and provides us with information such as the IP address of the computer wanting to view the image, the time the image was viewed, and the type of browser viewing the image. For example, through that technology we can see if an email or advertisement has been opened and acted upon. In short, cookies work together with web beacons to accomplish the functions and purposes described in this Cookie Policy.&nbsp;</p>
            <p>&nbsp;</p>
            <p><strong>3. What are cookies used for?</strong></p>
            <p>Cookies do lots of different jobs. They adapt the Website according to previously chosen preferences, help us understand how the Website is being used, let you navigate between pages efficiently, and help to improve your browsing experience in general. Cookies can also help ensure advertisements you see online are more relevant to your interests.&nbsp;</p>
            <p>&nbsp;</p>
            <p><strong>4. Types of cookies we use</strong></p>
            <p>The types of cookies used on most websites can generally be put into one of the following categories: strictly necessary, analytics, functionality, advertising, social media, and fraud prevention. In order to provide you with the best browsing experience, we and our third-party partners may use cookies from all of these categories. You can find out more about the cookies we use from each of the categories in the list below.</p>
            <p>&nbsp;</p>
            <p><strong>5. Strictly Necessary Cookies</strong></p>
            <p>These cookies are essential to make the Website work on your browsing device. They enable you to pass security checks and navigate through our Website and use its features and the Services. Without these cookies the use of the Website and the Services, that are necessary for you, can't be provided in principle or at a decent level.</p>
            <p>&nbsp;</p>
            <p><strong>6. Analytics Cookies</strong></p>
            <p>These cookies collect information about how Users and Members use the Website. For example, which pages are visited most often, how Users and Members move from one link to another, and whether they get error messages from certain pages. All in all, these cookies provide us with analytical information about how the Website works and how we can improve it.</p>
            <p>&nbsp;</p>
            <p><strong>7. Functionality Cookies</strong></p>
            <p>These cookies allow us to remember the choices you make and tailor our Website to provide enhanced features and content to you. For example, these cookies can be used to remember your username, language choice, or country selection and they can also be used to remember changes you have made to text size, font, and other parts of pages that you have previously customized.</p>
            <p>&nbsp;</p>
            <p><strong>8. Advertising Cookies</strong></p>
            <p>These cookies are being used to deliver advertisements on our Website, on other sites around the Web to users that have visited or used our Website or the Services, or to deliver advertisements about the Services that are relevant to you and your interests. These cookies also limit the number of times you see an advertisement as well as help measure and report the results and effectiveness of an advertising campaign. They remember that you have visited the Website and this information may be shared with other organizations such as advertisers. This means after you have visited our Website you may see advertisements about our Services or other services elsewhere on the Internet.</p>
            <p>&nbsp;</p>
            <p><strong>9. Social Media Cookies</strong></p>
            <p>To enhance your Internet experience and to make the sharing of content easier, some of the Website’s pages contain tools that are linked to third party social media service providers such as Facebook or Twitter. To enable automatic information exchange between us and these providers you will need to use a respective button, menu option, etc. Through these tools, third-party service providers, i.e. social media networks may set their own cookies on your device. We do not control these cookies and you should check these social media service provider's websites for further details about how they use cookies and how to control them.</p>
            <p>&nbsp;</p>
            <p><strong>10. Fraud Prevention Cookies</strong></p>
            <p>These cookies, flash storage tokens, smart phone SDK applications, or other software on the end user's device contain a unique identifier. Subsequently, in transactions between you and us, that identifier is accessed and checked through third party databases to determine if the device has been associated with suspected fraud or abuse reported by other online service providers in connection with prior transactions involving that device. How long will cookies stay on the device? The length of time a cookie will stay on your browsing device depends on whether it is a "persistent" or "session" cookies. Session cookies will only stay on your device until you stop browsing. Persistent cookies stay on your device after you have finished browsing and until they expire or are deleted.</p>
            <p>&nbsp;</p>
            <p><strong>11. First and third-party cookies</strong></p>
            <p>First-party cookies are cookies that belong to us and are mostly used to adapt the Website interface according to your preferences and for other purely technical reasons, like authorization, correct work of modules, etc. Third-party cookies are cookies that the third-party service providers/our partners place on your browsing device when you visit our Website and/or use our Services. These are used for more appropriate advertising, different analytics. Both first and third-party cookies can be analytics, functionality, and advertising cookies.</p>
            <p>&nbsp;</p>
            <p><strong>12. How to manage first-party Cookies</strong></p>
            <p>You are able to adjust the browser that you are using for our Website and the Services and enable, disable or delete Cookies. To do this, follow the instructions provided by your browser (usually located within the "Help", "Tools" or "Edit" settings). Please note that if you set your browser to disable cookies, you may not be able to access the secure areas of the Website and other parts of the Website may also not work properly. You may also be able to opt-out of some targeted advertising on your mobile device by selecting "limit ad tracking" (iOS) or "opt-out of interest-based ads" (Android). Here you may find more information on how to manage cookies in your browser:</p>
            <p>Chrome:&nbsp;<a href="https://support.google.com/chrome/answer/95647?hl=en">https://support.google.com/chrome/answer/95647?hl=en</a>&nbsp;</p>
            <p>Explorer:&nbsp;<a href="https://support.microsoft.com/en-us/products/windows?os=windows-10">https://support.microsoft.com/en-us/products/windows?os=windows-10</a>&nbsp;</p>
            <p>Safari:&nbsp;<a href="https://support.apple.com/kb/PH21411">https://support.apple.com/kb/PH21411</a>&nbsp;</p>
            <p>Firefox:&nbsp;<a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a>&nbsp;</p>
            <p>Opera:&nbsp;<a href="http://www.opera.com/help/tutorials/security/cookies/">http://www.opera.com/help/tutorials/security/cookies/</a>&nbsp;</p>
            <p>&nbsp;</p>
            <p><strong>13. How to manage third-party Cookies</strong></p>
            <p>Cookies that are placed on your browsing device by a third party while you are viewing our Website and may be managed through your browser settings (as described above), by selecting the Do Not Track option on your browser (though we have no control over and cannot confirm whether these third-party ad networks respond to the Do Not Track browser signal), or by checking the third party's website for more information about cookie management and how to "opt-out" of receiving cookies from them. For more information about third-party cookies used on our Website, please refer to the respective provider’s policies that we cooperate with. We also allow advertising networks to deliver cookies when you are viewing our Website and applications or ads on our Services, and these advertising partners may include Yahoo's Flurry service and may change from time to time. Analytics and advertising - Google, Yahoo, Bing.&nbsp;</p>
            <p>&nbsp;</p>
            <p><strong>14. Contact Us</strong></p>
            <p>If you have any questions about this Cookie Policy, please contact us:</p>
            <p>By email:&nbsp;<a href="mailto:support@love-in-one-click.com">support@love-in-one-click.com</a>.</p>
            <p>By visiting this page on our website:&nbsp;<a href="https://love-in-one-click.com/support">https://love-in-one-click.com/support</a>.</p>
            <p>&nbsp;</p>
            <p>Our Website located at&nbsp;<a href="https://love-in-one-click.com/">https://love-in-one-click.com</a>.</p>
            <p>&nbsp;</p>
            <p>Effective date: August 20, 2020</p>
        </>
    )
}