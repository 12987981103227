//v-version
//t-traffic
//p-post register
//c-captcha
//m-mini landings

export const LND20 = 'lnd20';
export const LND20M = 'lnd20m';
export const LND20V2 = 'lnd20v2';
export const LND20V2C = 'lnd20v2c';
export const LND21 = 'lnd21';
export const LND21V2 = 'lnd21v2';
export const LND22 = 'lnd22';
export const LND22V2 = 'lnd22v2';
export const LND23 = 'lnd23';
export const LND23V2 = 'lnd23v2';
export const LND24 = 'lnd24';
export const LND24V2 = 'lnd24v2';
export const LND25 = 'lnd25';
export const LND26 = 'lnd26';
export const LND27 = 'lnd27';
export const LND28 = 'lnd28';
export const LND29 = 'lnd29';
export const LND30 = 'lnd30';
export const LND30M = 'lnd30m';
export const LND30V2 = 'lnd30v2';
export const LND30P = 'lnd30p';
export const LND31 = 'lnd31';
export const LND30AF = 'lnd30af';
export const LND31AF = 'lnd31af';
export const LND32 = 'lnd32';
export const LND33 = 'lnd33';
export const LND34 = 'lnd34';
export const LND35 = 'lnd35';
export const LND36 = 'lnd36';
export const LND37 = 'lnd37';

export const SW50 = 'sw50';
export const SW50A = 'sw50a';

export const SW60 = 'sw60';
export const SW60A = 'sw60a';

export const SW40 = 'sw40';
export const SW40A = 'sw40a';

export const SWO50 = 'swo50';
export const SWO50A = 'swo50a';

export const UADS = 'uads';
export const UADSA = 'uadsa';

export const SSW50 = 'ssw50';
export const SSW50A = 'ssw50a';

export const SSW60 = 'ssw60';
export const SSW60A = 'ssw60a';

export const SSW40 = 'ssw40';
export const SSW40A = 'ssw40a';

export const SSWO50 = 'sswo50';
export const SSWO50A = 'sswo50a';

export const UADS2 = 'uads2';
export const UADS2A = 'uads2a';

// TRAFFIC ROUTE
export const LND20T = 'lnd20t';
export const LND20T_AD = 'lnd20t-ad';
export const LND20T_IV = 'lnd20t-iv';
export const LND23T = 'lnd23t';
export const LND23T_AD = 'lnd23t-ad';
export const LND25T = 'lnd25t';
export const LND30T = 'lnd30t';
export const LND30T_IV = 'lnd30t-iv';
export const LND31T = 'lnd31t';
export const SW50T = 'sw50t';
export const SW60T = 'sw60t';
export const SWO50T = 'swo50t';
export const ROUTE_LIST_TRAFFIC = [LND20T_IV, LND20T_AD, LND20T, LND23T_AD, LND23T, LND25T, LND30T_IV, LND30T, LND31T, SW50T, SW60T, SWO50T];

export const HIDE_GOOGLE_AUTH = [LND20, LND20T, LND30, LND30T, LND37];

export const REDIRECT_POST_REGISTRATION = [LND30P];

export const SOFIA_LOGO_REDIRECT = [];
export const MY_SPECIAL_LOGO_REDIRECT = [];

export const LOGIN_FORM = [LND20V2, LND30V2, LND20, LND30, LND20M, LND30M];

export const MINI_LANDINGS = [LND20M, LND30M];

export const ROUTE_LIST = [
    LND20T_IV, LND20T_AD, LND20T, LND23T_AD, LND23T, LND25T, LND30T_IV, LND30T, LND31T, SW50T, SW60T, SWO50T,
    SSW50A, SW50A, SSW50, SW50, SSW60A, SW60A, SSW60, SW60, SSW40A, SW40A, SSW40, SW40, SSWO50A, SWO50A, SSWO50, SWO50, UADSA, UADS2A, UADS2, UADS,
    LND20V2C, LND20V2, LND20M, LND20, LND21V2, LND21, LND22V2, LND22, LND23V2, LND23, LND24V2, LND24, LND25, LND26, LND27, LND28, LND29,
    LND30AF, LND30P, LND30V2, LND30M, LND30, LND31AF, LND31, LND32, LND33, LND34, LND35, LND36,LND37
];

export const ROUTE_SOURCE_MAP = {
    [LND20T_AD]: [LND20T_AD],
    [LND20V2]: [LND20V2],
    [LND20V2C]: [LND20V2C],
    [LND20M]: [LND20M],
    [LND20]: [LND20, SW50, SW50A, LND20T, SW50T, LND20T_IV],
    [LND21V2]: [LND21V2],
    [LND21]: [LND21, SW60, SW60A, SW60T],
    [LND22V2]: [LND22V2],
    [LND22]: [LND22, SW40, SW40A],
    [LND23V2]: [LND23V2],
    [LND23T_AD]: [LND23T_AD],
    [LND23]: [LND23, SWO50, SWO50A, LND23T, SWO50T],
    [LND24V2]: [LND24V2],
    [LND24]: [LND24, UADS, UADSA],
    [LND25]: [LND25, SSW50, SSW50A, LND25T],
    [LND26]: [LND26, SSW60, SSW60A],
    [LND27]: [LND27, SSW40, SSW40A],
    [LND28]: [LND28, SSWO50, SSWO50A],
    [LND29]: [LND29, UADS2, UADS2A],
    [LND30]: [LND30, LND30T, LND30AF, LND30T_IV, LND30P],
    [LND30M]: [LND30M],
    [LND30V2]: [LND30V2],
    [LND31]: [LND31, LND31T, LND31AF],
    [LND32]: [LND32],
    [LND33]: [LND33],
    [LND34]: [LND34],
    [LND35]: [LND35],
    [LND36]: [LND36],
    [LND37]: [LND37]
};